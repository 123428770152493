define("ember-google-maps/components/g-map/circle", ["exports", "ember-google-maps/components/g-map/marker"], function (_exports, _marker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Circle marker component.
   *
   * @class Circle
   * @namespace GMap
   * @module ember-google-maps/components/g-map/circle
   * @extends GMap.Marker
   */
  var _default = _marker.default.extend({
    _type: 'circle',
    _requiredOptions: ['center', 'radius'],
    radius: 500,
    center: Ember.computed.reads('position'),
    _addComponent: function _addComponent() {
      Ember.set(this, 'mapComponent', new google.maps.Circle(Ember.get(this, '_options')));
    }
  });

  _exports.default = _default;
});