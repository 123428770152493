define("ember-google-maps/components/-private-api/detect-render", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Empty component to watch the contents of overlays for changes. Pass in an
   * action on `didRender` to react to content changes.
   *
   * The overlay component is rerendered when the marker is repositioned on zoom.
   * As a result, it's not possible to tell what causes the rerender – changes in
   * the template or setting the new position. This separate component wrapper
   * lets us decouple the content block's rendering logic from the marker.
   *
   * @class DetectRender
   * @module ember-google-maps/components/-private-api/detect-render
   * @extends Component
   * @private
   */
  var _default = Ember.Component.extend({
    attributeBindings: ['style']
  });

  _exports.default = _default;
});