define("ember-attacher/components/attach-popover", ["exports", "ember-attacher/defaults", "ember-attacher/templates/components/attach-popover", "ember-attacher/-debug/helpers"], function (_exports, _defaults, _attachPopover, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _attachPopover.default,
    tagName: '',

    /**
     * ================== PUBLIC CONFIG OPTIONS ==================
     */
    animation: _defaults.default.animation,
    arrow: _defaults.default.arrow,
    class: _defaults.default.class,
    flip: _defaults.default.flip,
    hideDelay: _defaults.default.hideDelay,
    hideDuration: _defaults.default.hideDuration,
    hideOn: _defaults.default.hideOn,
    interactive: _defaults.default.interactive,
    isOffset: _defaults.default.isOffset,
    isShown: _defaults.default.isShown,
    lazyRender: _defaults.default.lazyRender,
    onChange: null,
    placement: _defaults.default.placement,
    popperContainer: _defaults.default.popperContainer,
    popperOptions: _defaults.default.popperOptions,
    popperTarget: null,
    renderInPlace: _defaults.default.renderInPlace,
    showDelay: _defaults.default.showDelay,
    showDuration: _defaults.default.showDuration,
    showOn: _defaults.default.showOn,
    style: _defaults.default.style,
    useCapture: _defaults.default.useCapture,

    /**
     * ================== PRIVATE IMPLEMENTATION DETAILS ==================
     */
    actions: {
      // Exposed via the named yield to enable custom hide events
      hide: function hide() {
        this._hide();
      },
      registerAPI: function registerAPI(api) {
        this._disableEventListeners = api.disableEventListeners;
        this._enableEventListeners = api.enableEventListeners;
        this._popperElement = api.popperElement;
        this._update = api.update;

        if (!this.isDestroying && !this.isDestroyed) {
          if (this.get('registerAPI') !== undefined) {
            this.registerAPI(api);
          }

          if (this._isHidden) {
            // Hide the attachment until it has been positioned,
            // preventing jank during initial positioning
            this._popperElement.style.visibility = 'hidden'; // The attachment has no width if initially hidden. This can cause it to be positioned so
            // far to the right that it overflows the screen until enough updates fix its position.
            // We avoid this by positioning initially hidden elements in the top left of the screen.
            // The attachment will then correctly update its position from the first this._show()

            this._popperElement.style.transform = null;
            this._popperElement.style.display = this.get('isShown') ? '' : 'none';
          }
        }
      }
    },
    // The circle element needs a special duration that is slightly faster than the popper's
    // transition, this prevents text from appearing outside the circle as it fills the background
    _circleTransitionDuration: Ember.computed('_transitionDuration', function () {
      return Ember.String.htmlSafe("transition-duration: ".concat(Math.round(this.get('_transitionDuration') / 1.25), "ms"));
    }),
    _class: Ember.computed('class', 'arrow', 'animation', '_isStartingAnimation', function () {
      var showOrHideClass = "ember-attacher-".concat(this.get('_isStartingAnimation') ? 'show' : 'hide');
      var arrowClass = "ember-attacher-".concat(this.get('arrow') ? 'with' : 'without', "-arrow");
      return "ember-attacher-".concat(this.get('animation'), " ").concat(this.get('class') || '', " ").concat(showOrHideClass, " ").concat(arrowClass);
    }),
    _style: Ember.computed('style', '_transitionDuration', function () {
      var style = this.get('style');
      var transitionDuration = this.get('_transitionDuration');
      (true && Ember.warn('@ember/string/htmlSafe must be used for any `style` passed to ember-attacher', Ember.isEmpty(style) || Ember.String.isHTMLSafe(style), {
        id: 'ember-attacher-require-html-safe-style'
      }));
      return Ember.String.htmlSafe("transition-duration: ".concat(transitionDuration, "ms; ").concat(style));
    }),
    // This is memoized so it can be used by both attach-popover and attach-tooltip
    _config: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment').emberAttacher || {};
    }),
    _hideOn: Ember.computed('hideOn', function () {
      var hideOn = this.get('hideOn');

      if (hideOn === undefined) {
        hideOn = _defaults.default.hideOn;
      }

      return hideOn === null ? [] : hideOn.split(' ');
    }),
    _modifiers: Ember.computed('arrow', 'flip', 'modifiers', function () {
      // Copy the modifiers since we might write to the provided hash
      var modifiers = this.get('modifiers') ? Object.assign({}, this.get('modifiers')) : {};
      var arrow = this.get('arrow');

      if (typeof arrow === 'boolean' && !modifiers.arrow) {
        modifiers.arrow = {
          enabled: arrow
        };
      }

      var flipString = this.get('flip');

      if (flipString) {
        var flipModifier = {
          behavior: flipString.split(' ')
        };

        if (!modifiers.flip) {
          modifiers.flip = flipModifier;
        } else if (!modifiers.flip.behavior) {
          // Copy the flip modifier since we are altering the provided hash
          modifiers.flip = Object.assign({}, modifiers.flip, flipModifier);
        }
      }

      return modifiers;
    }),
    _setIsVisibleAfterDelay: function _setIsVisibleAfterDelay(isVisible, delay) {
      var _this = this;

      if (!this._popperElement) {
        this._animationTimeout = requestAnimationFrame(function () {
          _this._animationTimeout = _this._setIsVisibleAfterDelay(isVisible, delay);
        });
        return;
      }

      var onChange = this.get('onChange');

      if (delay) {
        this._delayedVisibilityToggle = Ember.run.later(this, function () {
          _this._animationTimeout = requestAnimationFrame(function () {
            if (!_this.isDestroyed && !_this.isDestroying) {
              _this._popperElement.style.display = isVisible ? '' : 'none'; // Prevent jank by making the attachment invisible until positioned.
              // The visibility style will be toggled by this._startShowAnimation()

              _this._popperElement.style.visibility = isVisible ? 'hidden' : '';

              if (onChange) {
                onChange(isVisible);
              }
            }
          });
        }, delay);
      } else {
        this._popperElement.style.display = isVisible ? '' : 'none'; // Prevent jank by making the attachment invisible until positioned.
        // The visibility style will be toggled by this._startShowAnimation()

        this._popperElement.style.visibility = isVisible ? 'hidden' : '';

        if (onChange) {
          onChange(isVisible);
        }
      }
    },
    // This is set to true when the popover is shown in order to override lazyRender=false
    _mustRender: false,
    _showOn: Ember.computed('showOn', function () {
      var showOn = this.get('showOn');

      if (showOn === undefined) {
        showOn = _defaults.default.showOn;
      }

      return showOn === null ? [] : showOn.split(' ');
    }),
    _transitionDuration: 0,

    /**
     * ================== LIFECYCLE HOOKS ==================
     */
    init: function init() {
      this._super.apply(this, arguments); // Used to determine the attachments initial parent element


      this._parentFinder = self.document ? self.document.createTextNode('') : ''; // Holds the current popper target so event listeners can be removed if the target changes

      this._currentTarget = null; // The debounced _hide() and _show() are stored here so they can be cancelled when necessary

      this._delayedVisibilityToggle = null;
      this.id = this.id || "".concat(Ember.guidFor(this), "-popper"); // The final source of truth on whether or not all _hide() or _show() actions have completed

      this._isHidden = true; // Holds a delayed function to toggle the visibility of the attachment.
      // Used to make sure animations can complete before the attachment is hidden.

      this._animationTimeout = null; // Used to store event listeners so they can be removed when necessary.

      this._hideListenersOnDocumentByEvent = {};
      this._hideListenersOnTargetByEvent = {};
      this._showListenersOnTargetByEvent = {}; // Hacks to make sure event listeners have the right context and are still removable

      this._debouncedHideIfMouseOutsideTargetOrAttachment = this._debouncedHideIfMouseOutsideTargetOrAttachment.bind(this);
      this._hide = this._hide.bind(this);
      this._hideAfterDelay = this._hideAfterDelay.bind(this);
      this._hideIfMouseOutsideTargetOrAttachment = this._hideIfMouseOutsideTargetOrAttachment.bind(this);
      this._hideOnClickOut = this._hideOnClickOut.bind(this);
      this._hideOnEscapeKey = this._hideOnEscapeKey.bind(this);
      this._hideOnLostFocus = this._hideOnLostFocus.bind(this);
      this._hideOnMouseLeaveTarget = this._hideOnMouseLeaveTarget.bind(this);
      this._show = this._show.bind(this);
      this._showAfterDelay = this._showAfterDelay.bind(this);

      this._setUserSuppliedDefaults();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      (0, _helpers.stripInProduction)(function () {
        var attrs = _this2.get('attrs') || {};

        var userDefaults = _this2.get('_config');

        var arrow;

        if (attrs.arrow !== undefined) {
          arrow = attrs.arrow.value;
        } else if (userDefaults.arrow !== undefined) {
          arrow = userDefaults.arrow;
        } else {
          arrow = _defaults.default.arrow;
        }

        var animation;

        if (attrs.animation !== undefined) {
          animation = attrs.animation.value;
        } else if (userDefaults.animation !== undefined) {
          animation = userDefaults.animation;
        } else {
          animation = _defaults.default.animation;
        }

        if (arrow && animation === 'fill') {
          (true && Ember.warn('Animation: \'fill\' is not compatible with arrow: true', {
            id: 70015
          }));
        }

        _this2._lastUseCaptureArgumentValue = _this2.useCapture;
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this3 = this;

      this._super.apply(this, arguments);

      (0, _helpers.stripInProduction)(function () {
        if (_this3.useCapture !== _this3._lastUseCaptureArgumentValue) {
          (true && Ember.warn('The value of the useCapture argument was mutated', {
            id: 'ember-attacher.use-capture-mutated'
          }));
        }
      });
    },
    _setUserSuppliedDefaults: function _setUserSuppliedDefaults() {
      var _this4 = this;

      var userDefaults = this.get('_config'); // Exit early if no custom defaults are found

      if (!userDefaults) {
        return;
      }

      var attrs = this.get('attrs') || {};

      var _loop = function _loop(key) {
        (0, _helpers.stripInProduction)(function () {
          if (!_defaults.default.hasOwnProperty(key)) {
            (true && Ember.warn("Unknown property given as an ember-attacher default: ".concat(key), {
              id: 700152
            }));
          }
        }); // Don't override attrs manually passed into the component

        if (attrs[key] === undefined) {
          if (key === 'arrow') {
            _this4.set('arrow', userDefaults[key]);
          } else {
            _this4[key] = userDefaults[key];
          }
        }
      };

      for (var key in userDefaults) {
        _loop(key);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._initializeAttacher();
    },
    _initializeAttacher: function _initializeAttacher() {
      this._removeEventListeners();

      this.set('_currentTarget', this.get('popperTarget') || this._parentFinder.parentNode);

      this._addListenersForShowEvents();

      if (!this._isHidden || this.get('isShown')) {
        this._addListenersForHideEvents(); // Even if the attachment is already shown, we still want to
        // call this._show() to make sure its position is updated for a potentially new target.


        this._show();
      }
    },
    _addListenersForShowEvents: function _addListenersForShowEvents() {
      var _this5 = this;

      this.get('_showOn').forEach(function (event) {
        _this5._showListenersOnTargetByEvent[event] = _this5._showAfterDelay;

        _this5._currentTarget.addEventListener(event, _this5._showAfterDelay, _this5.get('useCapture'));
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      cancelAnimationFrame(this._animationTimeout);
      Ember.run.cancel(this._delayedVisibilityToggle);

      this._removeEventListeners();
    },
    _removeEventListeners: function _removeEventListeners() {
      var _this6 = this;

      Object.keys(this._hideListenersOnDocumentByEvent).forEach(function (eventType) {
        document.removeEventListener(eventType, _this6._hideListenersOnDocumentByEvent[eventType], _this6.get('useCapture'));
        delete _this6._hideListenersOnDocumentByEvent[eventType];
      });

      if (!this._currentTarget) {
        return;
      }

      [this._hideListenersOnTargetByEvent, this._showListenersOnTargetByEvent].forEach(function (eventToListener) {
        Object.keys(eventToListener).forEach(function (event) {
          _this6._currentTarget.removeEventListener(event, eventToListener[event], _this6.get('useCapture'));
        });
      });
    },
    _targetOrTriggersChanged: Ember.observer('hideOn', 'showOn', 'popperTarget', function () {
      this._initializeAttacher();
    }),
    _isShownChanged: Ember.observer('isShown', function () {
      var _this7 = this;

      var isShown = this.get('isShown');

      if (isShown === true && this._isHidden) {
        this._show(); // Add the hide listeners in the next run loop to avoid conflicts
        // where clicking triggers both an isShown toggle and a clickout.


        Ember.run.next(this, function () {
          return _this7._addListenersForHideEvents();
        });
      } else if (isShown === false && !this._isHidden) {
        this._hide();
      }
    }),

    /**
     * ================== SHOW ATTACHMENT LOGIC ==================
     */
    _showAfterDelay: function _showAfterDelay() {
      Ember.run.cancel(this._delayedVisibilityToggle);
      this.set('_mustRender', true);

      this._addListenersForHideEvents();

      var showDelay = parseInt(this.get('showDelay'));
      this._delayedVisibilityToggle = Ember.run.debounce(this, this._show, showDelay, !showDelay);
    },
    _show: function _show() {
      cancelAnimationFrame(this._animationTimeout);

      if (!this._currentTarget) {
        return;
      }

      this.set('_mustRender', true); // Make the attachment visible immediately so transition animations can take place

      this._setIsVisibleAfterDelay(true, 0);

      this._startShowAnimation();
    },
    _startShowAnimation: function _startShowAnimation() {
      var _this8 = this;

      // Start the show animation on the next cycle so CSS transitions can have an effect.
      // If we start the animation immediately, the transition won't work because
      // `display: none` => `display: ''` is not transition-able.
      // All included animations set opaque: 0, so the attachment is still effectively hidden until
      // the final RAF occurs.
      this._animationTimeout = requestAnimationFrame(function () {
        if (_this8.isDestroyed || _this8.isDestroying || !_this8._currentTarget) {
          return;
        }

        var popperElement = _this8._popperElement; // Wait until the element is visible before continuing

        if (!popperElement || popperElement.style.display === 'none') {
          _this8._animationTimeout = _this8._startShowAnimation();
          return;
        }

        _this8._enableEventListeners();

        _this8._update(); // Wait for the above positioning to take effect before starting the show animation,
        // else the positioning itself will be animated, causing animation glitches.


        _this8._animationTimeout = requestAnimationFrame(function () {
          if (_this8.isDestroyed || _this8.isDestroying || !_this8._currentTarget) {
            return;
          }

          Ember.run(function () {
            if (_this8.isDestroyed || _this8.isDestroying || !_this8._currentTarget) {
              return;
            } // Make the popper element visible now that it has been positioned


            popperElement.style.visibility = '';

            _this8.set('_transitionDuration', parseInt(_this8.get('showDuration')));

            _this8.set('_isStartingAnimation', true);

            popperElement.setAttribute('aria-hidden', 'false');
          });
          _this8._isHidden = false;
        });
      });
    },

    /**
     * ================== HIDE ATTACHMENT LOGIC ==================
     */
    _hideAfterDelay: function _hideAfterDelay() {
      Ember.run.cancel(this._delayedVisibilityToggle);
      var hideDelay = parseInt(this.get('hideDelay'));
      this._delayedVisibilityToggle = Ember.run.debounce(this, this._hide, hideDelay, !hideDelay);
    },
    _hide: function _hide() {
      var _this9 = this;

      if (!this._popperElement) {
        this._animationTimeout = requestAnimationFrame(function () {
          _this9._animationTimeout = _this9._hide();
        });
        return;
      }

      cancelAnimationFrame(this._animationTimeout);

      this._removeListenersForHideEvents();

      this._animationTimeout = requestAnimationFrame(function () {
        // Avoid a race condition where we attempt to hide after the component is being destroyed.
        if (_this9.isDestroyed || _this9.isDestroying) {
          return;
        }

        var hideDuration = parseInt(_this9.get('hideDuration'));
        Ember.run(function () {
          if (_this9.isDestroyed || _this9.isDestroying) {
            return;
          }

          _this9.set('_transitionDuration', hideDuration);

          _this9.set('_isStartingAnimation', false);

          _this9._popperElement.setAttribute('aria-hidden', 'true'); // Wait for any animations to complete before hiding the attachment


          _this9._setIsVisibleAfterDelay(false, hideDuration);
        });

        _this9._disableEventListeners();

        _this9._isHidden = true;
      });
    },

    /**
     * ================== HIDE LISTENERS ==================
     */
    _addListenersForHideEvents: function _addListenersForHideEvents() {
      var _this10 = this;

      var hideOn = this.get('_hideOn');
      var target = this._currentTarget; // Target or component was destroyed

      if (!target || this.isDestroyed || this.isDestroying) {
        return;
      }

      if (hideOn.indexOf('click') !== -1) {
        var showOnClickListener = this._showListenersOnTargetByEvent.click;

        if (showOnClickListener) {
          target.removeEventListener('click', showOnClickListener, this.get('useCapture'));
          delete this._showListenersOnTargetByEvent.click;
        }

        this._hideListenersOnTargetByEvent.click = this._hideAfterDelay;
        target.addEventListener('click', this._hideAfterDelay, this.get('useCapture'));
      }

      if (hideOn.indexOf('clickout') !== -1) {
        var clickoutEvent = 'ontouchstart' in window ? 'touchend' : 'click';
        this._hideListenersOnDocumentByEvent[clickoutEvent] = this._hideOnClickOut;
        document.addEventListener(clickoutEvent, this._hideOnClickOut, this.get('useCapture'));
      }

      if (hideOn.indexOf('escapekey') !== -1) {
        this._hideListenersOnDocumentByEvent.keydown = this._hideOnEscapeKey;
        document.addEventListener('keydown', this._hideOnEscapeKey, this.get('useCapture'));
      } // Hides the attachment when the mouse leaves the target
      // (or leaves both target and attachment for interactive attachments)


      if (hideOn.indexOf('mouseleave') !== -1) {
        this._hideListenersOnTargetByEvent.mouseleave = this._hideOnMouseLeaveTarget;
        target.addEventListener('mouseleave', this._hideOnMouseLeaveTarget, this.get('useCapture'));
      } // Hides the attachment when focus is lost on the target


      ['blur', 'focusout'].forEach(function (eventType) {
        if (hideOn.indexOf(eventType) !== -1) {
          _this10._hideListenersOnTargetByEvent[eventType] = _this10._hideOnLostFocus;
          target.addEventListener(eventType, _this10._hideOnLostFocus, _this10.get('useCapture'));
        }
      });
    },
    _hideOnMouseLeaveTarget: function _hideOnMouseLeaveTarget() {
      if (this.get('interactive')) {
        // TODO(kjb) Should debounce this, but hiding appears sluggish if you debounce.
        //   - If you debounce with immediate fire, you get a bug where you can move out of the
        //   attachment and not trigger the hide because the hide check was debounced
        //   - Ideally we would debounce with an immediate run, then instead of debouncing, we would
        //   queue another fire at the end of the debounce period
        if (!this._hideListenersOnDocumentByEvent.mousemove) {
          this._hideListenersOnDocumentByEvent.mousemove = this._hideIfMouseOutsideTargetOrAttachment;
          document.addEventListener('mousemove', this._hideIfMouseOutsideTargetOrAttachment, this.get('useCapture'));
        }
      } else {
        this._hideAfterDelay();
      }
    },
    _debouncedHideIfMouseOutsideTargetOrAttachment: function _debouncedHideIfMouseOutsideTargetOrAttachment(event) {
      Ember.run.debounce(this, this._hideIfMouseOutsideTargetOrAttachment, event, 10);
    },
    _hideIfMouseOutsideTargetOrAttachment: function _hideIfMouseOutsideTargetOrAttachment(event) {
      var target = this._currentTarget; // If cursor is not on the attachment or target, hide the popover

      if (!target.contains(event.target) && !(this.get('isOffset') && this._isCursorBetweenTargetAndAttachment(event)) && this._popperElement && !this._popperElement.contains(event.target)) {
        // Remove this listener before hiding the attachment
        delete this._hideListenersOnDocumentByEvent.mousemove;
        document.removeEventListener('mousemove', this._hideIfMouseOutsideTargetOrAttachment, this.get('useCapture'));

        this._hideAfterDelay();
      }
    },
    _isCursorBetweenTargetAndAttachment: function _isCursorBetweenTargetAndAttachment(event) {
      var clientX = event.clientX,
          clientY = event.clientY;

      var attachmentPosition = this._popperElement.getBoundingClientRect();

      var targetPosition = this._currentTarget.getBoundingClientRect();

      var isBetweenLeftAndRight = clientX > Math.min(attachmentPosition.left, targetPosition.left) && clientX < Math.max(attachmentPosition.right, targetPosition.right);
      var isBetweenTopAndBottom = clientY > Math.min(attachmentPosition.top, targetPosition.top) && clientY < Math.max(attachmentPosition.bottom, targetPosition.bottom); // Check if cursor is between a left-flipped attachment

      if (attachmentPosition.right < targetPosition.left && clientX >= attachmentPosition.right && clientX <= targetPosition.left && isBetweenTopAndBottom) {
        return true;
      } // Check if cursor is between a right-flipped attachment


      if (attachmentPosition.left > targetPosition.right && clientX <= attachmentPosition.left && clientX >= targetPosition.right && isBetweenTopAndBottom) {
        return true;
      } // Check if cursor is between a bottom-flipped attachment


      if (attachmentPosition.top > targetPosition.bottom && clientY <= attachmentPosition.top && clientY >= targetPosition.bottom && isBetweenLeftAndRight) {
        return true;
      } // Check if cursor is between a top-flipped attachment


      if (attachmentPosition.bottom < targetPosition.top && clientY >= attachmentPosition.bottom && clientY <= targetPosition.top && isBetweenLeftAndRight) {
        return true;
      }

      return false;
    },
    _hideOnClickOut: function _hideOnClickOut(event) {
      var targetReceivedClick = this._currentTarget.contains(event.target);

      if (this.get('interactive')) {
        if (!targetReceivedClick && !this._popperElement.contains(event.target)) {
          this._hideAfterDelay();
        }
      } else if (!targetReceivedClick) {
        this._hideAfterDelay();
      }
    },
    _hideOnEscapeKey: function _hideOnEscapeKey(event) {
      if (event.keyCode === 27) {
        return this._hideAfterDelay();
      }
    },
    _hideOnLostFocus: function _hideOnLostFocus(event) {
      if (event.relatedTarget === null) {
        this._hideAfterDelay();
      }

      var targetContainsFocus = this._currentTarget.contains(event.relatedTarget);

      if (this.get('interactive')) {
        if (!targetContainsFocus && !this._popperElement.contains(event.relatedTarget)) {
          this._hideAfterDelay();
        }
      } else if (!targetContainsFocus) {
        this._hideAfterDelay();
      }
    },
    _removeListenersForHideEvents: function _removeListenersForHideEvents() {
      var _this11 = this;

      Object.keys(this._hideListenersOnDocumentByEvent).forEach(function (eventType) {
        document.removeEventListener(eventType, _this11._hideListenersOnDocumentByEvent[eventType], _this11.get('useCapture'));
        delete _this11._hideListenersOnDocumentByEvent[eventType];
      });
      var showOn = this.get('_showOn');
      var target = this._currentTarget; // The target was destroyed, nothing to remove listeners from

      if (!target) {
        return;
      } // Switch clicking back to a show event


      if (showOn.indexOf('click') !== -1) {
        var hideOnClickListener = this._hideListenersOnTargetByEvent.click;

        if (hideOnClickListener) {
          target.removeEventListener('click', hideOnClickListener, this.get('useCapture'));
          delete this._hideListenersOnTargetByEvent.click;
        }

        this._showListenersOnTargetByEvent.click = this._showAfterDelay;
        target.addEventListener('click', this._showAfterDelay, this.get('useCapture'));
      }

      ['blur', 'focusout', 'mouseleave'].forEach(function (eventType) {
        var listener = _this11._hideListenersOnTargetByEvent[eventType];

        if (listener) {
          target.removeEventListener(eventType, listener, _this11.get('useCapture'));
          delete _this11._hideListenersOnTargetByEvent[eventType];
        }
      });
    }
  });

  _exports.default = _default;
});