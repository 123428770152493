define("ember-google-maps/templates/components/g-map/marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LgPF6ik3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"mapComponent\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"infoWindow\"],[[28,\"component\",[[24,[\"gMap\",\"infoWindow\"]]],[[\"target\"],[[23,0,[\"mapComponent\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/marker.hbs"
    }
  });

  _exports.default = _default;
});