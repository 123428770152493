define("ember-google-maps/utils/runloopify-google-maps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    var trigger = google.maps.event.trigger;
    google.maps.event.trigger = Ember.run.bind(google.maps.event, trigger);
  }
});