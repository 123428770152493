define("ember-google-maps/components/g-map/autocomplete", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/autocomplete"], function (_exports, _mapComponent, _autocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Autocomplete
   * @namespace GMap
   * @module ember-google-maps/components/g-map/autocomplete
   * @extends GMap.MapComponent
   */
  var _default = _mapComponent.default.extend({
    layout: _autocomplete.default,
    tagName: 'div',
    _type: 'autocomplete',
    _ignoredAttrs: ['onSearch'],
    init: function init() {
      this._super.apply(this, arguments);

      this.publicAPI.reopen({
        place: 'place'
      });
    },
    _addComponent: function _addComponent() {
      var map = Ember.get(this, 'map');
      var inputElement = this.element.querySelector('input');
      (true && !(inputElement) && Ember.assert('You must define your own input within the ember-google-maps autocomplete block.', inputElement));
      var autocomplete = new google.maps.places.Autocomplete(inputElement, Ember.get(this, '_options'));
      Ember.set(this, 'mapComponent', autocomplete); // Bias the search results to the current map bounds.

      autocomplete.setBounds(map.getBounds());
      map.addListener('bounds_changed', function () {
        autocomplete.setBounds(map.getBounds());
      });
      autocomplete.addListener('place_changed', this._onSearch.bind(this));
    },
    _onSearch: function _onSearch() {
      this.place = this.mapComponent.getPlace();

      if (this.place.geometry) {
        Ember.tryInvoke(this, 'onSearch', [this.publicAPI]);
      }
    }
  });

  _exports.default = _default;
});