define("ember-google-maps/components/g-map/control", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/control"], function (_exports, _mapComponent, _control) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Control
   * @namespace GMap
   * @module ember-google-maps/components/g-map/control
   * @extends GMap.MapComponent
   */
  var _default = _mapComponent.default.extend({
    layout: _control.default,
    class: undefined,
    tagName: 'div',
    _type: 'control',
    _requiredOptions: ['position'],
    _addComponent: function _addComponent() {
      var _elementDestination = Ember.set(this, '_elementDestination', document.createElement('div'));

      var map = Ember.get(this, 'map');

      if (Ember.get(this, 'class')) {
        _elementDestination.classList.add(Ember.get(this, 'class'));
      }

      var controlPosition = google.maps.ControlPosition[Ember.get(this, 'position')];
      map.controls[controlPosition].push(_elementDestination);
      Ember.set(this, 'mapComponent', _elementDestination);
    },
    _updateComponent: function _updateComponent() {}
  });

  _exports.default = _default;
});