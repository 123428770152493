define("ember-google-maps/components/g-map/canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Renders a canvas div into which the map is inserted.
   *
   * @class Canvas
   * @namespace GMap
   * @module ember-google-maps/components/g-map/canvas
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: '',
    _hasCustomCanvas: Ember.computed.bool('_customCanvas'),
    _shouldRenderDefaultCanvas: Ember.computed('_isInitialRender', '_hasCustomCanvas', function () {
      return Ember.get(this, '_isInitialRender') || !Ember.get(this, '_hasCustomCanvas');
    }),
    // TODO: Remove in Octane version. Use `...attributes` instead.
    computedClasses: Ember.computed('class', 'classNames', function () {
      var classes = ['ember-google-map', Ember.get(this, 'class'), Ember.get(this, 'classNames')];
      return classes.filter(function (x) {
        return x;
      }).join(' ');
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this._customCanvas) {
        return;
      } // TODO: Remove in Octane version. Splattributes somehow affect the
      // rendering loop, so this is necessary for 2.18.


      Ember.run.scheduleOnce('render', this, function () {
        var id = Ember.get(this, 'id');
        var canvas = document.getElementById(id);

        this._internalAPI._registerCanvas(canvas);
      });
    }
  });

  _exports.default = _default;
});