define("ember-cli-swiper/templates/components/swiper-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JSbrbWV9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"swiper-wrapper\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"slide\"],[[28,\"component\",[\"swiper-slide\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasPagination\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"swiper-pagination\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"hasNavigation\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"nextElClass\"]]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"prevElClass\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-swiper/templates/components/swiper-container.hbs"
    }
  });

  _exports.default = _default;
});