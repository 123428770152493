define("ember-attacher/templates/components/attach-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R/HhCxbs",
    "block": "{\"symbols\":[\"emberPopper\",\"&default\"],\"statements\":[[1,[28,\"unbound\",[[24,[\"_parentFinder\"]]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"_currentTarget\"]],[28,\"or\",[[28,\"not\",[[24,[\"lazyRender\"]]],null],[24,[\"_mustRender\"]]],null]],null]],null,{\"statements\":[[4,\"ember-popper\",null,[[\"ariaRole\",\"class\",\"eventsEnabled\",\"id\",\"modifiers\",\"placement\",\"popperContainer\",\"popperTarget\",\"registerAPI\",\"renderInPlace\"],[[24,[\"ariaRole\"]],\"ember-attacher\",false,[24,[\"id\"]],[24,[\"_modifiers\"]],[24,[\"placement\"]],[24,[\"popperContainer\"]],[24,[\"_currentTarget\"]],[28,\"action\",[[23,0,[]],\"registerAPI\"],null],[24,[\"renderInPlace\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"_class\"]]]],[11,\"style\",[22,\"_style\"]],[8],[0,\"\\n      \"],[14,2,[[28,\"hash\",null,[[\"emberPopper\",\"hide\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"hide\"],null]]]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"arrow\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"x-arrow\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"eq\",[[24,[\"animation\"]],\"fill\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"x-circle\",\"\"],[11,\"style\",[22,\"_circleTransitionDuration\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-attacher/templates/components/attach-popover.hbs"
    }
  });

  _exports.default = _default;
});