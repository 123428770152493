define("ember-sticky-element/templates/components/sticky-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+7EJTD8Q",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"sticky-element/trigger\",null,[[\"offset\",\"enter\",\"exit\",\"registerElement\"],[[24,[\"top\"]],[28,\"action\",[[23,0,[]],\"parentTopEntered\"],null],[28,\"action\",[[23,0,[]],\"parentTopExited\"],null],[28,\"action\",[[23,0,[]],\"registerTopTrigger\"],null]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"\\n  \",[22,\"containerClassName\"],\"\\n  \",[28,\"if\",[[24,[\"isSticky\"]],[24,[\"containerStickyClassName\"]]],null],\"\\n  \",[28,\"if\",[[24,[\"isStickyTop\"]],[24,[\"containerStickyTopClassName\"]]],null],\"\\n  \",[28,\"if\",[[24,[\"isStickyBottom\"]],[24,[\"containerStickyBottomClassName\"]]],null]]]],[11,\"style\",[22,\"containerStyle\"]],[8],[0,\"\\n\\n  \"],[14,1,[[28,\"hash\",null,[[\"isSticky\",\"isStickyTop\",\"isStickyBottom\"],[[24,[\"isSticky\"]],[24,[\"isStickyTop\"]],[24,[\"isStickyBottom\"]]]]]]],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"stickToBottom\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"sticky-element/trigger\",null,[[\"type\",\"offset\",\"enter\",\"exit\",\"registerElement\"],[\"bottom\",[24,[\"offsetBottom\"]],[28,\"action\",[[23,0,[]],\"parentBottomEntered\"],null],[28,\"action\",[[23,0,[]],\"parentBottomExited\"],null],[28,\"action\",[[23,0,[]],\"registerBottomTrigger\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-sticky-element/templates/components/sticky-element.hbs"
    }
  });

  _exports.default = _default;
});