define("ember-google-maps/components/g-map/info-window", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/info-window", "ember-google-maps/utils/helpers"], function (_exports, _mapComponent, _infoWindow, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A wrapper for the google.maps.InfoWindow class.
   *
   * @class InfoWindow
   * @namespace GMap
   * @module ember-google-maps/components/g-map/info-window
   * @extends GMap.MapComponent
   */
  var _default = _mapComponent.default.extend({
    layout: _infoWindow.default,
    _type: 'infoWindow',
    _ignoredAttrs: ['isOpen', 'target'],
    _requiredOptions: ['content'],
    isOpen: false,
    _cachedIsOpen: false,
    position: _helpers.position,
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'target')) {
        this._requiredOptions = this._requiredOptions.concat(['position']);
      }

      this.publicAPI.reopen({
        actions: {
          open: 'open',
          close: 'close'
        }
      });
    },
    _addComponent: function _addComponent() {
      this._prepareContent();

      var options = this._getOptions();

      Ember.set(this, 'mapComponent', new google.maps.InfoWindow(options));
    },
    _didAddComponent: function _didAddComponent() {
      this._openOrClose();

      this._super.apply(this, arguments);
    },
    _updateComponent: function _updateComponent() {
      var options = this._getOptions();

      this.mapComponent.setOptions(options);

      this._openOrClose();
    },
    _getOptions: function _getOptions() {
      var options = Ember.get(this, '_options');
      delete options.map;

      if (!Ember.get(this, 'isOpen')) {
        delete options.content;
      }

      return options;
    },
    _openOrClose: function _openOrClose() {
      var isOpen = Ember.get(this, 'isOpen');
      var isOpenChanged = this._cachedIsOpen !== isOpen;

      if (isOpenChanged && isOpen) {
        this.open();
      } else if (isOpenChanged && !isOpen) {
        this.close();
      }

      Ember.set(this, '_cachedIsOpen', isOpen);
    },
    _prepareContent: function _prepareContent() {
      if (!Ember.get(this, 'content')) {
        var content = document.createElement('div');
        Ember.set(this, '_targetPane', content);
        Ember.set(this, 'content', content);
      }
    },
    open: function open() {
      var _this = this;

      if (this.mapComponent) {
        google.maps.event.addListenerOnce(this.mapComponent, 'closeclick', function () {
          Ember.set(_this, 'isOpen', false);
        });
        this.mapComponent.open(Ember.get(this, 'map'), Ember.get(this, 'target'));
      }
    },
    close: function close() {
      if (this.mapComponent) {
        this.mapComponent.close();
      }
    }
  });

  _exports.default = _default;
});