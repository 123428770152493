define('ember-cli-social-share/components/email-share-button', ['exports', 'ember-cli-social-share/components/share-button', 'ember-cli-social-share/templates/components/email-share-button'], function (exports, _shareButton, _emailShareButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _shareButton.default.extend({
    tagName: 'a',
    layout: _emailShareButton.default,
    classNames: ['email-share-btn'],
    attributeBindings: ['target', 'href'],
    target: '_top',

    // Props
    recipient: '',
    subject: '',
    body: '',

    // Computed
    href: Ember.computed('recipient', 'subject', 'title', function () {
      var recipient = Ember.get(this, 'recipient');
      var subject = Ember.get(this, 'subject');
      var title = Ember.get(this, 'title');
      return 'mailto:' + recipient + '?subject=' + subject + '&amp;body=' + title;
    })

  });
});