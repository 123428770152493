define("ember-google-maps/services/google-maps-api", ["exports", "ember-google-maps/utils/helpers", "ember-google-maps/utils/runloopify-google-maps"], function (_exports, _helpers, _runloopifyGoogleMaps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class GoogleMapsApi
   * @extends Ember.Service
   * @module ember-google-maps/services/google-maps-api
   * @public
   */
  var _default = Ember.Service.extend({
    /**
     * @method google
     * @readOnly
     * @return {Ember.ObjectProxy}
     */
    google: (0, _helpers.computedPromise)(function () {
      return this._getApi();
    }).readOnly(),

    /**
     * @method directionsService
     * @readOnly
     * @return {Ember.ObjectProxy}
     */
    directionsService: (0, _helpers.computedPromise)(function () {
      return Ember.get(this, 'google').then(function () {
        return new google.maps.DirectionsService();
      });
    }).readOnly(),

    /**
     * By default, this returns the Google Maps URL created at build time. You can
     * use this hook to build the URL at runtime instead.
     *
     * This function returns a promise that resolves with the URL. This allows you
     * to use external data when building the URL. For example, you could fetch
     * the database record for the current user for localisation purposes.
     *
     * @method buildGoogleMapsUrl
     * @public
     * @param  {Object} config The ember-google-maps configuration.
     * @return {Promise<string>} The URL to the Google Maps API.
     */
    buildGoogleMapsUrl: function buildGoogleMapsUrl(config) {
      return Ember.RSVP.resolve(config['src']);
    },

    /**
     * Get the configuration for ember-google-maps set in environment.js. This
     * should contain your API key and any other options you set.
     *
     * @method _getConfig
     * @private
     * @return {Object}
     */
    _getConfig: function _getConfig() {
      return Ember.getOwner(this).resolveRegistration('config:environment')['ember-google-maps'];
    },

    /**
     * Return or load the Google Maps API.
     *
     * @method _getApi
     * @private
     * @return {Promise<object>}
     */
    _getApi: function _getApi() {
      if (typeof document === 'undefined') {
        return Ember.RSVP.reject();
      }

      var google = window.google;

      if (google && google.maps) {
        return Ember.RSVP.resolve(google);
      }

      var config = this._getConfig();

      return this.buildGoogleMapsUrl(config).then(this._loadAndInitApi);
    },
    _loadAndInitApi: function _loadAndInitApi(src) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        window.initGoogleMap = Ember.run.bind(function () {
          (0, _runloopifyGoogleMaps.default)();
          resolve(window.google);
        });
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;

        s.onerror = function (error) {
          return reject(error);
        }; // Insert into DOM to avoid CORS problems


        document.body.appendChild(s); // Load map

        s.src = "".concat(src, "&callback=initGoogleMap");
      });
    }
  });

  _exports.default = _default;
});