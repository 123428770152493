define("ember-fullcalendar/components/full-calendar", ["exports", "ember-fullcalendar/templates/components/full-calendar", "ember-invoke-action", "@fullcalendar/core", "fast-deep-equal"], function (_exports, _fullCalendar, _emberInvokeAction, _core, _fastDeepEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assign = Ember.assign,
      observer = Ember.observer,
      computed = Ember.computed,
      getOwner = Ember.getOwner;

  var _default = Ember.Component.extend(_emberInvokeAction.InvokeActionMixin, {
    /////////////////////////////////////
    // PROPERTIES
    /////////////////////////////////////
    layout: _fullCalendar.default,
    classNames: ['full-calendar'],
    calendar: undefined,
    /////////////////////////////////////
    // FULLCALENDAR OPTIONS
    /////////////////////////////////////
    // scheduler defaults to non-commercial license
    schedulerLicenseKey: computed(function () {
      // load the consuming app's config
      var applicationConfig = getOwner(this).resolveRegistration('config:environment');
      var defaultSchedulerLicenseKey = 'GPL-My-Project-Is-Open-Source';

      if (applicationConfig && applicationConfig.emberFullCalendar && applicationConfig.emberFullCalendar.schedulerLicenseKey) {
        return applicationConfig.emberFullCalendar.schedulerLicenseKey;
      }

      return defaultSchedulerLicenseKey;
    }),
    fullCalendarOptions: ['rerenderDelay', 'defaultRangeSeparator', // toolbar
    'header', 'footer', 'titleFormat', 'titleRangeSeparator', 'buttonText', 'buttonIcons', 'customButtons', // theme
    'themeSystem', 'bootstrapGlyphicons', 'bootstrapFontawesome', // sizing
    'height', 'contentHeight', 'aspectRatio', 'handleWindowResize', 'windowResizeDelay', // views
    'views', 'defaultView', 'fixedWeekCount', 'showNonCurrentDates', 'allDaySlot', 'allDayText', 'slotEventOverlap', 'timeGridEventMinHeight', // list
    'listDayFormat', 'listDayAltFormat', 'noEventsMessage', // timeline
    'resourceGroupField', 'resourceGroupText', 'resourceAreaWidth', 'resourceLabelText', 'resourceColumns', 'resourcesInitiallyExpanded', 'slotWidth', 'datesAboveResources', // custom views
    'duration', 'dayCount', 'visibleRange', // date & time
    'weekends', 'hiddenDays', 'columnHeader', 'columnHeaderFormat', 'columnHeaderText', 'columnHeaderHtml', 'slotDuration', 'slotLabelInterval', 'slotLabelFormat', 'minTime', 'maxTime', 'scrollTime', // date navigation
    'defaultDate', 'dateIncrement', 'dateAlignment', 'validRange', // date nav links
    'navLinks', 'navLinkDayClick', 'navLinkWeekClick', // week numbers
    'weekNumbers', 'weekNumbersWithinDays', 'weekNumberCalculation', 'weekLabel', // selection
    'selectable', 'selectMirror', 'unselectAuto', 'unselectCancel', 'selectOverlap', 'selectConstraint', 'selectAllow', 'selectMinDistance', // now indicator
    'nowIndicator', 'now', // business hours
    'businessHours', // event model
    'eventDataTransform', 'allDayDefault', 'defaultTimedEventDuration', 'defaultAllDayEventDuration', 'forceEventDuration', // event sources
    'events', 'eventSources', 'startParam', 'endParam', 'timezoneParam', 'lazyFetching', // event display
    'eventColor', 'eventBackgroundColor', 'eventBorderColor', 'eventTextColor', 'eventTimeFormat', 'displayEventTime', 'displayEventEnd', 'nextDayThreshold', 'eventOrder', 'progressiveEventRendering', // event dragging & resizing
    'editable', 'eventStartEditable', 'eventResizableFromStart', 'eventDurationEditable', 'eventResourceEditable', 'droppable', 'eventDragMinDistance', 'dragRevertDuration', 'dragScroll', 'snapDuration', 'allDayMaintainDuration', 'eventOverlap', 'eventConstraint', 'eventAllow', 'dropAccept', // event popover
    'eventLimit', 'eventLimitClick', 'eventLimitText', 'dayPopoverFormat', // resource data
    'resources', 'refetchResourcesOnNavigate', // resources
    'resourceOrder', 'filterResourcesWithEvents', 'resourceText', // international
    'locale', 'locales', 'firstDay', 'dir', // timezone
    'timeZone', // accessibility
    'longPressDelay', 'eventLongPressDelay', 'selectLongPressDelay', // plugins
    'plugins'],
    fullCalendarEvents: [// sizing
    'windowResize', // view api
    'viewSkeletonRender', 'viewSkeletonDestroy', 'datesRender', 'datesDestroy', // date & time
    'dayRender', // date clicking & selecting
    'dateClick', 'select', 'unselect', // event sources
    'eventSourceSuccess', 'eventSourceFailure', 'loading', // event display
    'eventRender', 'eventPositioned', 'eventDestroy', // clicking and hovering
    'eventClick', 'eventMouseEnter', 'eventMouseLeave', // event dragging & resizing
    'eventDragStart', 'eventDragStop', 'eventDrop', 'drop', 'eventReceive', 'eventLeave', 'eventResizeStart', 'eventResizeStop', 'eventResize', // resource rendering
    'resourceRender'],
    /////////////////////////////////////
    // SETUP/TEARDOWN
    /////////////////////////////////////
    didInsertElement: function didInsertElement() {
      var calendarOptions = this.getOptions();
      var calendarEvents = this.getEvents();
      var options = assign({}, calendarOptions, calendarEvents);
      this.setProperties({
        calendarOptions: calendarOptions,
        calendarEvents: calendarEvents
      }); // Temporary patch for `eventDataTransform` method throwing error

      options.eventDataTransform = this.get('eventDataTransform'); // add the license key for the scheduler

      options.schedulerLicenseKey = this.get('schedulerLicenseKey');
      var calendar = new _core.Calendar(this.element, options);
      this.set('calendar', calendar);
      calendar.render();
    },
    willDestroyElement: function willDestroyElement() {
      this.get('calendar').destroy();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var updates = {};
      var removals = [];
      var newOptions = this.getOptions();
      removals = this.removedAttrs(this.calendarOptions, newOptions, removals);
      updates = this.updatedAttrs(this.calendarOptions, newOptions, updates);

      if (!(0, _fastDeepEqual.default)(this.calendarOptions, newOptions)) {
        this.setProperties({
          calendarOptions: newOptions
        });
      }

      this.calendar.mutateOptions(updates, removals, false, _fastDeepEqual.default);
    },

    /*
     * Get removed attributes
     */
    removedAttrs: function removedAttrs(prevAttrs, attrs, removals) {
      for (var attrName in prevAttrs) {
        if (!(attrName in attrs)) {
          removals.push(attrName);
        }
      }

      return removals;
    },

    /*
     * Get updated attributes
     */
    updatedAttrs: function updatedAttrs(prevAttrs, attrs, updates) {
      for (var attrName in attrs) {
        if (!(0, _fastDeepEqual.default)(attrs[attrName], prevAttrs[attrName])) {
          updates[attrName] = attrs[attrName];
        }
      }

      return updates;
    },

    /*
     * Returns all of the valid Fullcalendar options that
     * were passed into the component.
     */
    getOptions: function getOptions() {
      var _this = this;

      var fullCalendarOptions = this.get('fullCalendarOptions');
      var options = {}; // Apply FullCalendar options based on property name

      fullCalendarOptions.forEach(function (optionName) {
        if (_this.get(optionName) !== undefined) {
          options[optionName] = _this.get(optionName);
        }
      }); // Handle overriden properties

      if (this.get('viewName') !== undefined) {
        options['defaultView'] = this.get('viewName');
      }

      if (this.get('date') !== undefined) {
        options['defaultDate'] = this.get('date');
      }

      return options;
    },

    /*
     * Returns all valid actions of Full calendar
     * that were passed into the component
     */
    getEvents: function getEvents() {
      var _this2 = this;

      var actions = {};
      this.getPassedEvents().forEach(function (eventName) {
        // create an event handler that runs the function inside an event loop.
        actions[eventName] = function () {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          Ember.run.schedule('actions', _this2, function () {
            _this2.invokeAction.apply(_this2, [eventName].concat(args, [_this2.get('calendar')]));
          });
        };
      });
      return actions;
    },

    /*
     * Returns all of the valid Fullcalendar callback event
     * names that were passed into the component.
     */
    getPassedEvents: function getPassedEvents() {
      var _this3 = this;

      return this.get('fullCalendarEvents').filter(function (eventName) {
        var methodName = "_".concat(eventName);
        return _this3.get(methodName) !== undefined || _this3.get(eventName) !== undefined;
      });
    },
    /////////////////////////////////////
    // OBSERVERS
    /////////////////////////////////////

    /**
     * Observe the events array for any changes and
     * re-render if changes are detected
     */
    observeEvents: observer('events.[]', function () {
      var _this4 = this;

      var events = this.get('events');
      this.get('calendar').batchRendering(function () {
        _this4.get('calendar').getEvents().forEach(function (e) {
          return e.remove();
        });

        if (events) {
          _this4.get('calendar').addEventSource(_this4.get('events'));
        }
      });
    }),

    /**
     * Observe the eventSources array for any changes and
     * re-render if changes are detected
     */
    observeEventSources: observer('eventSources.[]', function () {
      var _this5 = this;

      this.get('calendar').batchRendering(function () {
        _this5.get('calendar').getEventSources().forEach(function (e) {
          return e.remove();
        });

        _this5.get('eventSources').forEach(function (source) {
          if (source) {
            _this5.get('calendar').addEventSource(source);
          }
        });
      });
    }),

    /**
     * Observes the resources array and refreshes the resource view
     * if any changes are detected
     * @type {[type]}
     */
    observeResources: observer('resources.[]', function () {
      this.get('calendar').refetchResources();
    }),

    /**
     * Observes the 'viewName' property allowing FullCalendar view to be
     * changed from outside of the component.
     */
    viewNameDidChange: observer('viewName', function () {
      var viewName = this.get('viewName');
      var viewRange = this.get('viewRange');
      this.get('calendar').changeView(viewName, viewRange); // Call action if it exists

      if (this.get('onViewChange')) {
        this.get('onViewChange')(viewName, viewRange);
      }
    }),

    /**
     * Observes `date` property allowing date to be changed from outside
     * of the component.
     */
    dateDidChange: observer('date', function () {
      var date = this.get('date');
      this.get('calendar').gotoDate(date);
    })
  });

  _exports.default = _default;
});