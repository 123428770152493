define("ember-google-maps/templates/components/g-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d8Yfvura",
    "block": "{\"symbols\":[\"gMap\",\"addons\",\"&default\"],\"statements\":[[4,\"with\",[[28,\"hash\",null,[[\"canvas\",\"publicAPI\",\"map\",\"_internalAPI\"],[[28,\"component\",[\"g-map/canvas\"],[[\"id\",\"classNames\",\"_internalAPI\",\"_isInitialRender\",\"_customCanvas\"],[[23,0,[\"mapId\"]],[23,0,[\"classNames\"]],[23,0,[\"_internalAPI\"]],[23,0,[\"_isInitialRender\"]],[23,0,[\"_customCanvas\"]]]]],[23,0,[\"publicAPI\"]],[23,0,[\"map\"]],[23,0,[\"_internalAPI\"]]]]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"_isInitialRender\"]]],null,{\"statements\":[[0,\"    \"],[14,3,[[23,1,[]]]],[0,\"\\n\"],[0,\"    \"],[1,[28,\"g-map/compute\",[[28,\"action\",[[23,0,[]],[23,0,[\"_endInitialRender\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"_needsCanvas\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,1,[\"canvas\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"-private-api/addon-factory\",null,[[\"gMap\",\"map\",\"_internalAPI\"],[[23,0,[\"gMap\"]],[23,1,[\"map\"]],[23,1,[\"_internalAPI\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"g-map/compute\",[[28,\"action\",[[23,0,[]],[23,0,[\"_updateGMap\"]]],null],[23,1,[]],[23,2,[]]],null],false],[0,\"\\n\\n      \"],[14,3,[[23,0,[\"gMap\"]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map.hbs"
    }
  });

  _exports.default = _default;
});