define('ember-string-helpers/helpers/substr', ['exports', 'ember'], function (exports, _ember) {
	exports.substr = substr;

	function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

	function substr(_ref, hash) {
		var _ref2 = _toArray(_ref);

		var value = _ref2[0];

		var rest = _ref2.slice(1);

		if (typeof value === 'string') {
			var start = hash.start || 0;
			var _length = hash.length;
			return value.substr(start, _length);
		} else {
			return value;
		}
	}

	exports['default'] = _ember['default'].Helper.helper(substr);
});