define("ember-tracker/mixins/tealium-route", ["exports", "ember-tracker/-privates/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_VIEW = void 0;
  var DEFAULT_VIEW = {
    customerId: null,
    domain: Ember.getWithDefault(window || {}, 'location.hostname', ''),
    order_currency: 'USD',
    page_type: 'home'
  };
  _exports.DEFAULT_VIEW = DEFAULT_VIEW;

  var _default = Ember.Mixin.create({
    /**
     * Sets the utag.
     * @public
     * @memberOf {Mixin.TealiumRoute}
     * @override
     * @return {undefined}
     */
    init: function init() {
      this.setProperties({
        _etLastView: null,
        _tealium: null
      });

      this._super.apply(this, arguments);

      if (!Ember.testing && _utils.IN_BROWSER) {
        this._etCheckForUtag();
      }
    },

    /**
     * Checks for the utag param on the window and sets it. if there was a previous call to transition, send it.
     * @private
     * @memberOf {TealiumRoute}
      * @return {undefined}
      */
    _etCheckForUtag: function _etCheckForUtag() {
      var _this = this;

      Ember.run(function () {
        return _this.set('_tealium', window && window.utag);
      });

      if (this.get('_tealium')) {
        var lastView = this.get('_etLastView');

        if (lastView) {
          this.get('_tealium').view(lastView);
        }

        return;
      } // Run this later if they are using onLoad instead of inserting immediately


      Ember.run.later(this, '_etCheckForUtag', 500);
    },

    /**
     * Returns the route required.
     * @private
     * @memberOf {TealiumRoute}
     * @return {Route}
     */
    _etGetCurrentRoute: function _etGetCurrentRoute(routeName) {
      return (0, _utils.getCurrentRoute)(this, routeName);
    },

    /**
     * Watches the transition event and sends a new view to Tealium.
     * @private
     * @memberOf {Mixin.TealiumRoute}
     * @observer
     * @return {undefined}
     */
    _etTealium: Ember.on('didTransition', function () {
      var routeName = this.get('currentRouteName'),
          route = this._etGetCurrentRoute(routeName),
          hasTealiumFn = Ember.typeOf(route.getTealiumView) === 'function',
          utag = this.get('_tealium'),
          currView = {};

      (true && !("".concat(routeName, " route doesn't have a \"getTealiumView\" function")) && Ember.assert(hasTealiumFn, "".concat(routeName, " route doesn't have a \"getTealiumView\" function")));
      (0, _utils.mergeObjects)(currView, DEFAULT_VIEW);

      if (hasTealiumFn) {
        (0, _utils.mergeObjects)(currView, route.getTealiumView());
      }

      if (utag) {
        utag.view(currView);
      } else {
        this.set('_etLastView', currView);
      }
    })
  });

  _exports.default = _default;
});