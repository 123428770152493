define('ember-resize-observer/mixins/resize-observable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this._resizeObservableHeight = null;
      this._resizeObservableWidth = null;

      this.resizeObserver = new ResizeObserver(function (entries) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = entries[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var entry = _step.value;

            if (_this._resizeObservableHeight || _this._resizeObservableWidth) {
              _this.observedResize();
            }

            var _entry$contentRect = entry.contentRect,
                width = _entry$contentRect.width,
                height = _entry$contentRect.height;


            _this._resizeObservableHeight = height;
            _this._resizeObservableWidth = width;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this.resizeObserver.observe(this.element);
    },
    willDestroyElement: function willDestroyElement() {
      this.resizeObserver.unobserve(this.element);
    },
    observedResize: function observedResize() {}
  });
});