define("ember-collapsible-panel/components/cp-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C//BbdKf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"toggle\",\"body\",\"name\",\"isOpen\"],[[28,\"component\",[\"cp-panel-toggle\"],[[\"on-click\",\"isOpen\"],[[28,\"action\",[[23,0,[]],\"toggleIsOpen\"],null],[24,[\"isOpen\"]]]]],[28,\"component\",[\"cp-panel-body\"],[[\"shouldAnimate\",\"isOpen\"],[[24,[\"shouldAnimate\"]],[24,[\"isOpen\"]]]]],[24,[\"name\"]],[24,[\"isOpen\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-collapsible-panel/components/cp-panel/template.hbs"
    }
  });

  _exports.default = _default;
});