define('ember-cli-social-share/components/reddit-share-button', ['exports', 'ember-cli-social-share/components/share-button', 'ember-cli-social-share/templates/components/reddit-share-button'], function (exports, _shareButton, _redditShareButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _shareButton.default.extend({
    layout: _redditShareButton.default,
    shareURL: 'http://www.reddit.com/submit',
    classNames: ['reddit-share-btn'],

    click: function click() {
      var url = this.get('shareURL');
      url += '?url=' + encodeURIComponent(this.getCurrentUrl());
      url += this.get('title') ? '&title=' + this.get('title') : '';

      this.openSharePopup(url);
    }
  });
});