define("ember-google-maps/components/g-map/marker", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/marker", "ember-google-maps/utils/helpers"], function (_exports, _mapComponent, _marker, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A wrapper for the google.maps.Marker class.
   *
   * @class Marker
   * @namespace GMap
   * @module ember-google-maps/components/g-map/marker
   * @extends GMap.MapComponent
   */
  var _default = _mapComponent.default.extend({
    layout: _marker.default,
    tagName: '',
    _type: 'marker',
    _requiredOptions: ['position'],
    position: _helpers.position,
    _addComponent: function _addComponent() {
      Ember.set(this, 'mapComponent', new google.maps.Marker(Ember.get(this, '_options')));
    }
  });

  _exports.default = _default;
});