define("ember-place-autocomplete/services/google-place-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var google = this.google || (window ? window.google : null);

      if (google && document) {
        var googlePlaces = google.maps.places;
        var autocompleteService = new googlePlaces.AutocompleteService();
        var placesService = new googlePlaces.PlacesService(document.createElement('div'));
        var sessionToken = new googlePlaces.AutocompleteSessionToken();
        this.setProperties({
          autocompleteService: autocompleteService,
          google: google,
          sessionToken: sessionToken,
          placesService: placesService
        });
      }
    },
    getPlacePredictions: function getPlacePredictions(properties) {
      var _this = this;

      if (!properties.hasOwnProperty('input')) {
        return Ember.RSVP.Promise.reject(new Error('[service/google-place-autocomplete] input property was not passed inside properties object param'));
      }

      if (Ember.isBlank(properties.input)) {
        return Ember.RSVP.Promise.resolve([]);
      }

      properties.sessionToken = this.sessionToken;
      return new Ember.RSVP.Promise(function (resolve) {
        _this.autocompleteService.getPlacePredictions(properties, _this._googleResponseCallback.bind(_this, [resolve], []));
      });
    },
    getQueryPredictions: function getQueryPredictions(properties) {
      var _this2 = this;

      if (!properties.hasOwnProperty('input')) {
        return Ember.RSVP.Promise.reject(new Error('[service/google-place-autocomplete] input property was not passed inside properties object param'));
      }

      if (Ember.isBlank(properties.input)) {
        return Ember.RSVP.Promise.resolve([]);
      }

      return new Ember.RSVP.Promise(function (resolve) {
        _this2.autocompleteService.getQueryPredictions(properties, _this2._googleResponseCallback.bind(_this2, [resolve], []));
      });
    },
    getDetails: function getDetails(request) {
      var _this3 = this;

      request.sessionToken = this.sessionToken;

      if (!request.hasOwnProperty('fields') && !request.hasOwnProperty('placeId')) {
        return Ember.RSVP.Promise.reject(new Error('[service/google-place-autocomplete] getDetails needs the placeId and fields as properties of the request params'));
      }

      this.updateSessionToken();
      return new Ember.RSVP.Promise(function (resolve) {
        _this3.placesService.getDetails(request, _this3._googleResponseCallback.bind(_this3, [resolve], {}));
      });
    },
    _googleResponseCallback: function _googleResponseCallback(promiseCallbacks, failResponseReturnValue, requestResult, status) {
      var google = this.google || (window ? window.google : null);

      var _promiseCallbacks = _slicedToArray(promiseCallbacks, 1),
          resolve = _promiseCallbacks[0];

      if (status === google.maps.places.PlacesServiceStatus.OK) {
        return resolve(requestResult);
      }

      return resolve(failResponseReturnValue);
    },
    updateSessionToken: function updateSessionToken() {
      var googlePlaces = this.google.maps.places;
      this.set('sessionToken', new googlePlaces.AutocompleteSessionToken());
    }
  });

  _exports.default = _default;
});