define('ember-inject-scripts/utils/inject-scripts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = injectScripts;
  var _Ember$RSVP = Ember.RSVP,
      Promise = _Ember$RSVP.Promise,
      all = _Ember$RSVP.all,
      merge = Ember.merge;


  var scripts;

  var cacheEventItems = function cacheEventItems(e) {
    var isTrusted = e.isTrusted,
        path = e.path,
        returnValue = e.returnValue,
        srcElement = e.srcElement,
        timeStamp = e.timeStamp,
        target = e.target,
        type = e.type;

    return merge({ isTrusted: isTrusted, path: path, returnValue: returnValue, srcElement: srcElement, timeStamp: timeStamp, target: target, type: type }, e);
  };

  var removeEvents = function removeEvents($ele, script) {
    $ele.removeEventListener('load', script.onloadCallback);
    $ele.removeEventListener('error', script.onerrorCallback);
  };

  var injectScript = function injectScript(script) {

    var promise = void 0;

    // once
    if (script.once) {
      var $existingScript = document.getElementById(script.id);
      if ($existingScript) {
        promise = new Promise(function (resolve) {
          var customEvent = { target: $existingScript };
          resolve(customEvent);
        });
        return promise;
      }
    }

    //  create element

    var $scriptEl = document.createElement('script');

    $scriptEl.type = script.type ? script.type : 'text/javascript';

    if (script.id) {
      $scriptEl.id = script.id;
      $scriptEl.setAttribute('data-test-script', script.id);
    }

    if (script.attributes) {
      script.attributes.forEach(function (attribute) {
        $scriptEl.setAttribute(attribute.name, attribute.value);
      });
    }

    // for inline script
    if (script.inline) {
      var $inlineNode = document.createTextNode(script.inline);
      $scriptEl.appendChild($inlineNode);
      promise = new Promise(function (resolve) {
        var customInlineEvent = { target: $scriptEl };
        resolve(customInlineEvent);
      });
    }

    // for external scripts
    if (script.src) {

      $scriptEl.src = script.src;

      promise = new Promise(function (resolve) {

        // appending onload callback
        script.onloadCallback = function (e) {
          resolve(cacheEventItems(e));
          if (script.onload) {
            script.onload(e);
          }
          removeEvents($scriptEl, script);
        };

        // appending onerror callback
        script.onerrorCallback = function (e) {
          resolve(cacheEventItems(e));
          if (script.onerror) {
            script.onerror(e);
          }
          removeEvents($scriptEl, script);
          // removing element
          $scriptEl.remove();
        };

        // success
        $scriptEl.addEventListener('load', script.onloadCallback);

        // error
        $scriptEl.addEventListener('error', script.onerrorCallback);
      });
    }
    $scriptEl.async = true;

    document.getElementsByTagName('head')[0].appendChild($scriptEl);

    return promise;
  };

  function injectScripts(scriptObjects) {

    scripts = scriptObjects;

    if (Array.isArray(scripts)) {

      var promisePool = [];
      scripts.forEach(function (script) {
        var promise = injectScript(script);
        promisePool.push(promise);
      });

      return all(promisePool);
    } else if (typeof scripts === 'string') {
      var script = { src: scripts };
      return injectScript(script);
    } else {
      return injectScript(scripts);
    }
  }
});