define("ember-google-maps/mixins/process-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function addObserver(obj, key, callback) {
    var listener = obj.addObserver(key, callback);
    return {
      name: key,
      listener: listener,
      remove: function remove() {
        return obj.removeObserver(key, callback);
      }
    };
  }

  function watch(target) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return Object.entries(options).map(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          callback = _ref2[1];

      return addObserver(target, key, callback);
    });
  }
  /**
   * @class ProcessOptions
   * @module ember-google-maps/mixins/process-options
   * @extends Ember.Mixin
   */


  var _default = Ember.Mixin.create({
    concatenatedProperties: ['_requiredOptions', '_watchedOptions', '_ignoredAttrs'],

    /**
     * Specify which attributes on the component should be ignored and never
     * considered as a Google Maps option or event.
     *
     * @property _ignoredAttrs
     * @private
     * @type {String[]}
     */
    _ignoredAttrs: ['map', '_internalAPI', 'gMap', 'lat', 'lng', 'events', '_name'],

    /**
     * Required options that are always included in the options object passed to
     * the map component.
     *
     * @property _requiredOptions
     * @private
     * @type {String[]}
     */
    _requiredOptions: [],

    /**
     * Paths to watch for changes. The paths follow the same syntax as the keys
     * for Ember observers and computed properties.
     *
     * @property _watchedOptions
     * @private
     * @type {String[]}
     */
    _watchedOptions: [],

    /**
     * Combined object of options and events used to set and update the options
     * on the map component.
     *
     * @property options
     * @public
     * @return {Object}
     */
    options: Ember.computed('attrs', 'events', function () {
      var _EmberGetProperties = Ember.getProperties(this, '_ignoredAttrs', '_eventAttrs'),
          _ignoredAttrs = _EmberGetProperties._ignoredAttrs,
          _eventAttrs = _EmberGetProperties._eventAttrs;

      var ignoredAttrs = [].concat(_toConsumableArray(_ignoredAttrs), _toConsumableArray(_eventAttrs));
      var attrs = Object.keys(this.attrs).filter(function (attr) {
        return ignoredAttrs.indexOf(attr) === -1;
      });
      return Ember.getProperties(this, attrs);
    }),
    _options: Ember.computed('map', 'options', function () {
      var options = Ember.get(this, 'options');

      var _requiredOptions = Ember.get(this, '_requiredOptions');

      var required = Ember.getProperties(this, _requiredOptions);
      return Ember.assign(required, options);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this._watchedListeners = new Map();

      if (!this._eventAttrs) {
        this._eventAttrs = [];
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._watchedListeners.forEach(function (remove) {
        return remove();
      });

      this._super.apply(this, arguments);
    },
    _registerOptionObservers: function _registerOptionObservers() {
      var _this = this;

      var _watchedOptions = Ember.get(this, '_watchedOptions');

      if (_watchedOptions.length === 0) {
        return;
      }

      function update() {
        if (this._isInitialized) {
          this._updateComponent();
        }
      }

      var watched = {};

      _watchedOptions.forEach(function (path) {
        return watched[path] = update.bind(_this);
      });

      watch(this, watched).forEach(function (_ref3) {
        var name = _ref3.name,
            remove = _ref3.remove;
        return _this._watchedListeners.set(name, remove);
      });
    }
  });

  _exports.default = _default;
});