define("ember-google-maps/components/g-map/waypoint", ["exports", "ember-google-maps/mixins/process-options"], function (_exports, _processOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A utility component to add waypoints to the directions component.
   *
   * @class Waypoint
   * @namespace GMap
   * @module ember-google-maps/components/g-map/waypoint
   * @extends Ember.Component
   * @uses ProcessOptions
   */
  var _default = Ember.Component.extend(_processOptions.default, {
    tagName: '',
    _requiredOptions: ['location'],
    _ignoredAttrs: ['_registerWaypoint', '_unregisterWaypoint'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._registerWaypoint(Ember.get(this, '_options'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._unregisterWaypoint(Ember.get(this, '_options'));
    }
  });

  _exports.default = _default;
});