define('ember-cli-social-share/components/google-share-button', ['exports', 'ember-cli-social-share/components/share-button', 'ember-cli-social-share/templates/components/google-share-button'], function (exports, _shareButton, _googleShareButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _shareButton.default.extend({
    layout: _googleShareButton.default,
    shareURL: '//plus.google.com/share',
    classNames: ['google-share-btn'],

    click: function click() {
      var url = this.get('shareURL');
      url += '?url=' + encodeURIComponent(this.getCurrentUrl());
      this.openSharePopup(url);
    }
  });
});