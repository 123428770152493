define("ember-google-maps/components/g-map/route", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/route"], function (_exports, _mapComponent, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A wrapper for the google.maps.DirectionsRenderer class.
   *
   * @class Route
   * @namespace GMap
   * @module ember-google-maps/components/g-map/route
   * @extends GMap.MapComponent
   */
  var _default = _mapComponent.default.extend({
    layout: _route.default,
    tagName: '',
    _type: 'route',
    _requiredOptions: ['directions'],
    _addComponent: function _addComponent() {
      var options = Ember.get(this, '_options');

      if (!options.directions) {
        return Ember.RSVP.reject();
      }

      Ember.set(this, 'mapComponent', new google.maps.DirectionsRenderer(options));
    }
  });

  _exports.default = _default;
});