define("ember-google-maps/templates/components/g-map/canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DM+rscvl",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"_customCanvas\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"_customCanvas\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"_shouldRenderDefaultCanvas\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"id\",[22,\"id\"]],[12,\"class\",[22,\"computedClasses\"]],[12,\"style\",[22,\"style\"]],[13,1],[8],[14,2],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/canvas.hbs"
    }
  });

  _exports.default = _default;
});