define("ember-toggle/components/x-toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p3apL7UM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[10,\"class\",\"x-toggle\"],[11,\"checked\",[22,\"toggled\"]],[11,\"disabled\",[22,\"disabled\"]],[11,\"id\",[22,\"forId\"]],[11,\"name\",[22,\"name\"]],[11,\"onchange\",[28,\"action\",[[23,0,[]],[24,[\"sendToggle\"]]],[[\"value\"],[\"target.checked\"]]]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\\n\"],[7,\"label\",true],[11,\"for\",[22,\"effectiveForId\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"id\",[29,[\"x-toggle-visual-\",[22,\"forId\"]]]],[10,\"role\",\"checkbox\"],[11,\"class\",[29,[\"x-toggle-btn \",[22,\"themeClass\"],\" \",[22,\"size\"],[28,\"if\",[[24,[\"disabled\"]],\" x-toggle-disabled\"],null]]]],[11,\"aria-owns\",[22,\"forId\"]],[11,\"aria-checked\",[22,\"toggled\"]],[11,\"data-tg-on\",[22,\"onLabel\"]],[11,\"data-tg-off\",[22,\"offLabel\"]],[8],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-toggle/components/x-toggle-switch/template.hbs"
    }
  });

  _exports.default = _default;
});