define('ember-cli-social-share/components/fb-share-button', ['exports', 'ember-cli-social-share/components/share-button', 'ember-cli-social-share/templates/components/fb-share-button'], function (exports, _shareButton, _fbShareButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _shareButton.default.extend({
    layout: _fbShareButton.default,
    shareURL: 'https://facebook.com/sharer.php',
    classNames: ['fb-share-btn'],

    // Computed
    hashtag: Ember.computed('hashtags', function () {
      if (this.get('hashtags')) {
        var firstTag = this.get('hashtags').split(',').shift();
        return encodeURIComponent('#' + firstTag);
      }
    }),

    // Events
    click: function click() {
      var url = this._buildUrl();
      this.openSharePopup(url);
    },


    // Methods
    _buildUrl: function _buildUrl() {
      var quote = this.get('quote') ? '&quote=' + this.get('quote') : '';
      var hashtag = this.get('hashtag') ? '&hashtag=' + this.get('hashtag') : '';
      var currentUrl = encodeURIComponent(this.getCurrentUrl());
      var url = this.get('shareURL') + '?display=popup&u=' + currentUrl + hashtag + quote;
      return url;
    }
  });
});