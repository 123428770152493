define("ember-test-selectors/utils/bind-data-test-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = bindDataTestAttributes;
  var TEST_SELECTOR_PREFIX = /data-test-.*/;

  function bindDataTestAttributes(component) {
    var dataTestProperties = [];

    for (var attr in component) {
      if (TEST_SELECTOR_PREFIX.test(attr)) {
        dataTestProperties.push(attr);
      }
    }

    if (dataTestProperties.length === 0) {
      return;
    }

    var tagName = component.get('tagName');

    if (component.get('supportsDataTestProperties') && tagName === '') {
      return;
    }

    var message = "ember-test-selectors could not bind data-test-* properties on ".concat(component, " ") + "automatically because tagName is empty. If you did this intentionally, see " + "https://github.com/simplabs/ember-test-selectors#usage-in-computed-properties " + "for instructions on how to disable this assertion.";
    (true && !(tagName !== '') && Ember.assert(message, tagName !== '', {
      id: 'ember-test-selectors.empty-tag-name'
    }));
    var attributeBindings = component.getWithDefault('attributeBindings', []);

    if (!Ember.isArray(attributeBindings)) {
      attributeBindings = [attributeBindings];
    } else {
      attributeBindings = attributeBindings.slice();
    }

    dataTestProperties.forEach(function (it) {
      return attributeBindings.push(it);
    });

    try {
      component.set('attributeBindings', attributeBindings);
    } catch (error) {
      var _message = "ember-test-selectors could not bind data-test-* properties on ".concat(component, " ") + "automatically because \"attributeBindings\" is a read-only property.";

      (true && !(false) && Ember.assert(_message, false, {
        id: 'ember-test-selectors.computed-attribute-bindings'
      }));
    }
  }
});