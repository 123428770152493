define("ember-google-maps/templates/components/g-map/directions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JVT7vGOv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"directions\",\"route\",\"waypoint\"],[[24,[\"directions\"]],[28,\"component\",[[24,[\"gMap\",\"route\"]]],[[\"directions\"],[[24,[\"directions\"]]]]],[28,\"component\",[\"g-map/waypoint\"],[[\"_registerWaypoint\",\"_unregisterWaypoint\"],[[28,\"action\",[[23,0,[]],[24,[\"_registerWaypoint\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"_unregisterWaypoint\"]]],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/directions.hbs"
    }
  });

  _exports.default = _default;
});