define("ember-google-maps/components/g-map/polyline", ["exports", "ember-google-maps/components/g-map/map-component"], function (_exports, _mapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A wrapper for the google.maps.Polyline class.
   *
   * @class Polyline
   * @namespace GMap
   * @module ember-google-maps/components/g-map/polyline
   * @extends GMap.MapComponent
   */
  var _default = _mapComponent.default.extend({
    _type: 'polyline',
    _requiredOptions: ['path'],
    _watchedOptions: ['path.[]'],
    _addComponent: function _addComponent() {
      Ember.set(this, 'mapComponent', new google.maps.Polyline(Ember.get(this, '_options')));
    }
  });

  _exports.default = _default;
});