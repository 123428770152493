define("ember-google-maps/templates/components/g-map/info-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fLQ9u39F",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"_targetPane\"]]],null,{\"statements\":[[4,\"in-element\",[[24,[\"_targetPane\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[24,[\"publicAPI\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[22,\"content\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/info-window.hbs"
    }
  });

  _exports.default = _default;
});