define("ember-google-maps/mixins/register-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Add event listeners on a target object using the cross-browser event
   * listener library provided by the Google Maps API.
   *
   * @param {Object} target
   * @param {Events} events
   * @param {[Object]} payload = {} An optional object of additional parameters
   *     to include with the event payload.
   * @return {google.maps.MapsEventListener[]} An array of bound event listeners
   *     that should be used to remove the listeners when no longer needed.
   */
  function _addEventListeners(target, events) {
    var payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return Object.entries(events).map(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          originalEventName = _ref2[0],
          action = _ref2[1];

      return _addEventListener(target, originalEventName, action, payload);
    });
  }

  function _addEventListener(target, originalEventName, action) {
    var payload = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    var eventName = Ember.String.decamelize(originalEventName).slice(3);

    function callback(googleEvent) {
      var params = _objectSpread({
        event: window.event,
        googleEvent: googleEvent,
        eventName: eventName,
        target: target
      }, payload);

      Ember.run.join(target, action, params);
    }

    var listener = google.maps.event.addDomListener(target, eventName, callback);
    return {
      name: eventName,
      listener: listener,
      remove: function remove() {
        return listener.remove();
      }
    };
  }
  /**
   * Register Google Maps events on any map component.
   *
   * The mixin filters the component attributes for those that begin with `on` and
   * are not on the `_ignoredAttrs` list. The attribute name is decamelize and the
   * `on` prefixed is dropped to generate the event name. The attribute function
   * is then bound to that event by name.
   *
   * For example, passing `onClick` will add a `click` event that will
   * call the function passed in as `onClick`.
   *
   * @class RegisterEvents
   * @module ember-google-maps/mixins/register-events
   * @extends Ember.Mixin
   */


  var _default = Ember.Mixin.create({
    /**
     * @property events
     * @type {Object}
     * @public
     */

    /**
     * The target DOM node or Google Maps object to which to attach event
     * listeners.
     *
     * @property eventTarget
     * @type {HTMLNode|MVCObject}
     * @private
     */
    _eventTarget: Ember.computed.reads('mapComponent'),

    /**
     * Filter the array of passed attributes for attributes that begin with `on`.
     *
     * @property _eventAttrs
     * @private
     * @return {Array} An array of extracted event names.
     */
    _eventAttrs: Ember.computed('attrs', function () {
      var _this = this;

      var attrNames = Object.keys(this.attrs);
      return attrNames.filter(function (attr) {
        return _this._filterEventsByName(attr);
      });
    }),

    /**
     * A combination of events passed via the `events` hash and extracted from the
     * component's attributes. Events registered via an attribute take precedence.
     *
     * @property _events
     * @private
     * @return {Object}
     */
    _events: Ember.computed('events', '_eventAttrs', function () {
      var events = Ember.get(this, 'events');
      var extractedEvents = Ember.getProperties(this, Ember.get(this, '_eventAttrs'));
      return Ember.assign({}, events, extractedEvents);
    }),

    /**
     * Return true if the passed attribute matches the syntax for an event, i.e.
     * begins with `on` and is not explicitly ignored in `_ignoredAttrs`.
     *
     * @method _filterEventsByName
     * @param {String} attr
     * @private
     * @return {Boolean}
     */
    _filterEventsByName: function _filterEventsByName(attr) {
      return attr.slice(0, 2) === 'on' && Ember.get(this, '_ignoredAttrs').indexOf(attr) === -1;
    },
    init: function init() {
      this._super.apply(this, arguments);

      this._eventListeners = new Map();
    },
    willDestroyElement: function willDestroyElement() {
      this._eventListeners.forEach(function (remove) {
        return remove();
      });

      this._super.apply(this, arguments);
    },

    /**
     * Register an event listener on the eventTarget for each event provided.
     *
     * @method registerEvents
     * @private
     * @return
     */
    registerEvents: function registerEvents() {
      var _this2 = this;

      var eventTarget = Ember.get(this, '_eventTarget');
      var events = Ember.get(this, '_events');
      var payload = {
        publicAPI: this.publicAPI,
        map: Ember.get(this, 'map')
      };

      _addEventListeners(eventTarget, events, payload).forEach(function (_ref3) {
        var name = _ref3.name,
            remove = _ref3.remove;
        return _this2._eventListeners.set(name, remove);
      });
    }
  });

  _exports.default = _default;
});