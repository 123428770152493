define("ember-attacher/defaults", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    animation: 'fill',
    arrow: false,
    class: null,
    flip: null,
    hideDelay: 0,
    hideDuration: 300,
    hideOn: 'mouseleave blur escapekey',
    interactive: false,
    isOffset: false,
    isShown: false,
    lazyRender: false,
    modifiers: null,
    placement: 'top',
    popperContainer: '.ember-application',
    popperOptions: null,
    renderInPlace: false,
    showDelay: 0,
    showDuration: 300,
    showOn: 'mouseenter focus',
    style: null,
    tooltipClass: 'ember-attacher-popper ember-attacher-tooltip',
    useCapture: false
  };
  _exports.default = _default;
});