define("ember-intercom-io/services/intercom", ["exports", "intercom"], function (_exports, _intercom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WarnOption = {
    id: 'ember-intercom-io.missing-data'
  };
  /**
   * Normalization function for converting intercom data to a consistent format.
   *
   * Changes:
   * - underscore keys
   * - convert dates to unix timestamps
   *
   * @param  {Object} data
   *
   * @private
   * @return {Object}
   */

  function normalizeIntercomMetadata(data) {
    var result = {};
    var val;
    Object.keys(data).forEach(function (key) {
      val = data[key];

      if (Ember.typeOf(val) === 'object') {
        result[Ember.String.underscore(key)] = normalizeIntercomMetadata(val);
      } else {
        if (Ember.typeOf(val) === 'date') {
          val = val.valueOf();
        }

        if (Ember.typeOf(val) !== 'undefined') {
          result[Ember.String.underscore(key)] = val;
        }
      }
    });
    return result;
  }

  var _default = Ember.Service.extend(Ember.Evented, {
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'user', {
        email: null,
        name: null,
        hash: null,
        user_id: null
      });
    },
    api: _intercom.default,
    user: null,

    /**
     * [description]
     * @return {[type]} [description]
     */
    _userHashProp: Ember.computed('user', 'config.userProperties.userHashProp', function () {
      return Ember.get(this, "user.".concat(Ember.get(this, 'config.userProperties.userHashProp')));
    }),
    _userIdProp: Ember.computed('user', 'config.userProperties.userIdProp', function () {
      return Ember.get(this, "user.".concat(Ember.get(this, 'config.userProperties.userIdProp')));
    }),
    _userNameProp: Ember.computed('user', 'config.userProperties.nameProp', function () {
      return Ember.get(this, "user.".concat(Ember.get(this, 'config.userProperties.nameProp')));
    }),
    _userEmailProp: Ember.computed('user', 'config.userProperties.emailProp', function () {
      return Ember.get(this, "user.".concat(Ember.get(this, 'config.userProperties.emailProp')));
    }),
    _userCreatedAtProp: Ember.computed('user', 'config.userProperties.createdAtProp', function () {
      return Ember.get(this, "user.".concat(Ember.get(this, 'config.userProperties.createdAtProp')));
    }),

    /**
     * Indicates the open state of the Intercom panel
     *
     * @public
     * @type {Boolean}
     */
    isOpen: false,

    /**
     * Indicates whether the Intercom boot command has been called.
     *
     * @public
     * @readonly
     * @type {Boolean}
     */
    isBooted: false,
    _hasUserContext: Ember.computed('user', '_userEmailProp', '_userIdProp', function () {
      return !!Ember.get(this, 'user') && (!!Ember.get(this, '_userEmailProp') || !!Ember.get(this, '_userIdProp'));
    }),

    /**
     * Reports the number of unread messages
     *
     * @public
     * @type {Number}
     */
    unreadCount: 0,

    /**
     * If true, will automatically update intercom when changes to user object are made.
     *
     * @type {Boolean}
     * @public
     */
    autoUpdate: true,

    /**
     * Hide the default Intercom launcher button
     *
     * @public
     * @type {Boolean}
     */
    hideDefaultLauncher: false,

    /**
     * @private
     * alias for appId
     * @type {[type]}
     */
    appId: Ember.computed.alias('config.appId'),
    start: function start() {
      var bootConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _bootConfig = Ember.assign(Ember.get(this, '_intercomBootConfig'), bootConfig);

      this.boot(_bootConfig);
    },
    stop: function stop() {
      return this.shutdown();
    },

    /**
     * Boot intercom window
     * @param  {Object} [config={}] [description]
     * @public
     */
    boot: function boot() {
      var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this._callIntercomMethod('boot', normalizeIntercomMetadata(config));

      this._addEventHandlers();

      this.set('isBooted', true);
    },

    /**
     * Update intercom data
     * @param  {Object} [config={}] [description]
     * @public
     */
    update: function update() {
      var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (!this.get('isBooted')) {
        (true && Ember.warn('Cannot call update before boot', WarnOption));
        return;
      }

      var _hasUserContext = this.get('_hasUserContext');

      if (_hasUserContext) {
        this._callIntercomMethod('update', normalizeIntercomMetadata(config));
      } else {
        (true && Ember.warn('Refusing to send update to Intercom because user context is incomplete. Missing userId or email', WarnOption));
      }
    },

    /**
     * shutdown Intercom window
     * @public
     */
    shutdown: function shutdown() {
      this.set('isBooted', false);
      this._hasEventHandlers = false;

      this._callIntercomMethod('shutdown');
    },

    /**
     * Show intercom window
     * @public
     */
    show: function show() {
      return this._wrapIntercomCallInPromise('show', 'show');
    },

    /**
     * hide intercom window
     * @public
     */
    hide: function hide() {
      return this._wrapIntercomCallInPromise('hide', 'hide');
    },
    toggleOpen: function toggleOpen() {
      this.get('isOpen') ? this.hide() : this.show();
    },

    /**
     * Opens the message window with the message list visible.
     *
     * @public
     * @return {Promise}
     */
    showMessages: function showMessages() {
      return this._wrapIntercomCallInPromise('showMessages', 'show');
    },

    /**
     * Opens the message window with the new message view.
     *
     * @public
     * @return {Promise}
     */
    showNewMessage: function showNewMessage(initialText) {
      return this._wrapIntercomCallInPromise('showNewMessage', 'show', initialText);
    },

    /**
     * You can submit an event using the trackEvent method.
     * This will associate the event with the currently logged in user and
     * send it to Intercom.
     *
     * The final parameter is a map that can be used to send optional
     * metadata about the event.
     *
     * @param {String} eventName
     * @param {Object} metadata
     * @public
     */
    trackEvent: function trackEvent() {
      this._callIntercomMethod.apply(this, ['trackEvent'].concat(Array.prototype.slice.call(arguments)));
    },

    /**
     * A visitor is someone who goes to your site but does not use the messenger.
     * You can track these visitors via the visitor user_id. This user_id
     * can be used to retrieve the visitor or lead through the REST API.
     *
     * @public
     * @return {String} The visitor ID
     */
    getVisitorId: function getVisitorId() {
      return this.get('api')('getVisitorId');
    },

    /**
     * Private on hide
     * @private
     * @return {[type]} [description]
     */
    _onHide: function _onHide() {
      this.set('isOpen', false);
      this.trigger('hide');
    },

    /**
     * handle onShow events
     * @private
     */
    _onShow: function _onShow() {
      this.set('isOpen', true);
      this.trigger('show');
    },

    /**
     * Handle onUnreadCountChange Events
     * @param  {[type]} count [description]
     * @private
     */
    _onUnreadCountChange: function _onUnreadCountChange(count) {
      this.set('unreadCount', Number(count));
    },
    _addEventHandlers: function _addEventHandlers() {
      var _this = this;

      if (this._hasEventHandlers) {
        return;
      }

      this._callIntercomMethod('onHide', function () {
        return Ember.run.next(_this, '_onHide');
      });

      this._callIntercomMethod('onShow', function () {
        return Ember.run.next(_this, '_onShow');
      });

      this._callIntercomMethod('onUnreadCountChange', function (count) {
        _this._onUnreadCountChange(count);
      });

      this._hasEventHandlers = true;
    },
    _wrapIntercomCallInPromise: function _wrapIntercomCallInPromise(intercomMethod, eventName) {
      var _this2 = this;

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return new Promise(function (resolve) {
        var isOpen = _this2.get('isOpen');

        if (eventName === 'show' && isOpen || eventName === 'hide' && !isOpen) {
          Ember.run.next(_this2, resolve);
        } else {
          _this2.one(eventName, resolve);
        }

        _this2._callIntercomMethod.apply(_this2, [intercomMethod].concat(args));
      });
    },
    _callIntercomMethod: function _callIntercomMethod(methodName) {
      var intercom = this.get('api');

      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      intercom.apply(void 0, [methodName].concat(args));
    },
    // eslint-disable-next-line ember/no-observers
    userDataDidChange: Ember.observer('user.@each', function () {
      if (this.get('autoUpdate') && this.get('isBooted')) {
        var user = this.get('_computedUser');
        var appId = this.get('appId');
        var config = Ember.assign({
          app_id: appId
        }, user);
        this.update(config);
      }
    }),

    /**
     * Alias for computed user data with app-provided config values
     * @private
     * @type {[type]}
     */
    _computedUser: Ember.computed('user.@each', 'user', '_userHashProp', '_userIdProp', '_userNameProp', '_userEmailProp', '_userCreatedAtProp', function () {
      (true && !(this.get('appId')) && Ember.assert('You must supply an "ENV.intercom.appId" in your "config/environment.js" file.', this.get('appId')));
      var obj = {};

      if (this.get('user')) {
        var userProps = Object.values(Ember.get(this, 'config.userProperties')),
            user = Ember.get(this, 'user'),
            userKeys = Object.keys(user);
        userKeys.forEach(function (k) {
          if (!userProps.includes(k) && !obj.hasOwnProperty(k)) {
            obj[k] = user[k];
          }
        });
        obj.user_hash = Ember.get(this, '_userHashProp');
        obj.user_id = Ember.get(this, '_userIdProp');
        obj.name = Ember.get(this, '_userNameProp');
        obj.email = Ember.get(this, '_userEmailProp');

        if (Ember.get(this, '_userCreatedAtProp')) {
          // eslint-disable-next-line
          obj.created_at = Ember.get(this, '_userCreatedAtProp');
        }
      }

      return obj;
    }),
    _intercomBootConfig: Ember.computed('config', 'user.@each', '_hasUserContext', 'hideDefaultLauncher', function () {
      var appId = Ember.get(this, 'config.appId');
      var user = Ember.get(this, '_computedUser');

      var _hasUserContext = Ember.get(this, '_hasUserContext');

      var hideDefaultLauncher = Ember.get(this, 'hideDefaultLauncher');
      (true && !(appId) && Ember.assert('You must supply an "ENV.intercom.appId" in your "config/environment.js" file.', appId));
      var obj = {
        app_id: appId
      };

      if (hideDefaultLauncher) {
        obj.hideDefaultLauncher = true;
      }

      if (_hasUserContext) {
        obj = Ember.assign({}, obj, user);
      }

      return normalizeIntercomMetadata(obj);
    })
  });

  _exports.default = _default;
});